const ArrowRight = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        className="transition-all duration-[0.4s]"
        d="M10.5 8L14.5 12L10.5 16"
        stroke="#777"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ArrowRight
